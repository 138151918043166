.contact-info-row{
    overflow: hidden;
    position: relative;
    height: auto;
    padding: 8% 10%;
    background-color: #fff;
    background: #fff;
    text-align: left;
}
.contact-info-row .title{
    font-family: 'Rajdhani',sans-serif;
    font-size: 34px;
    padding: 0;
    margin: 0;
    text-transform: capitalize;
    font-weight: 600;
    margin-bottom: 2%;
}
.contact-info-row .contact-info-col .info{
    margin-bottom: 4%;
}
.contact-info-row .contact-info-col .info:last-child{
    margin-bottom: 0;
}
.contact-info-col .info-child{
    margin-bottom: 2%;
}
.contact-info-col .child-two{
    margin-bottom: 1%;
}
.contact-info-row .contact-info-col h4{
    font-family: 'Rajdhani',sans-serif;
    font-size: 19px;
    font-weight: 600;
    padding: 0;
    margin: 0;
}
.contact-info-row .contact-info-col span{
    font-family: 'Rajdhani',sans-serif;
    font-size: 16px;
    font-weight: 500;
}
.contact-info-row .contact-info-col a{
    font-family: 'Rajdhani',sans-serif;
    color: #000;
    font-size: 16px;
    font-weight: 500;
}