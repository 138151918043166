.the-footer-row{
    font-family: 'Rajdhani',sans-serif;
    padding: 8%;
    padding-top: 4%;
    padding-bottom: 5%;
    overflow: hidden;
    text-align: center;
}
.the-footer-row .footer-text h1{
    font-family: 'Rajdhani',sans-serif;
    font-weight: 600;
    font-size: 150px;
    padding: 0;
    margin: 0;
    margin-bottom: 2%;
    text-transform: uppercase;
}
.footer-info-row .footer-title{
    text-align: left;
    font-family: 'Rajdhani',sans-serif;
    font-weight: 600;
    font-size: 18px;
}
.footer-info-row a{
    display: block !important;
    text-align: left;
    font-family: 'Rajdhani',sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #000;
}

 /*** RESPONSIVE AREA ***/
@media only screen and (min-width:480px) {
    /* .the-footer-row{
        padding-left: 2%;
        padding-right: 2%;
    } */
}
@media only screen and (min-width:768px) {
    .the-footer-row .footer-col{
        width: 33.33%;
        flex: 0 0 33.33%;
        max-width: 33.33%;
    }
    .the-footer-row .footer-col-6{
        width: 66.66%;
        flex: 0 0 66.66%;
        max-width: 66.66%;
    }
    .the-footer-row .footer-text h1{
        font-size: 80px;
    }
}
@media only screen and (min-width:980px) {
    .the-footer-row .footer-col{
        width: 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }
    .the-footer-row .footer-col-6{
        width: 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }
    .the-footer-row .footer-text h1{
        font-size: 85px;
    }
}
@media only screen and (min-width:1366px) {
    .the-footer-row .footer-col{
        width: 12.5%;
        flex: 0 0 12.5%;
        max-width: 12.5%;
    }
    .the-footer-row .footer-col-6{
        width: 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }
    .the-footer-row .footer-text h1{
        font-size: 120px;
    }
}
@media only screen and (min-width:1920px) {
    .the-footer-row .footer-col{
        width: 12.5%;
        flex: 0 0 12.5%;
        max-width: 12.5%;
    }
    .the-footer-row .footer-col-6{
        width: 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }
    .the-footer-row .footer-text h1{
        font-size: 150px;
    }
}