
html, body {
    max-width: 100%;
    overflow-x: hidden;
}
::-webkit-scrollbar {
    display: none;
}
.ant-layout .ant-layout-header{
    background: #fff;
    padding-inline: 0px;
    padding: 0px;
    height: auto;
    animation-duration: 2s;
    /* animation-name: testAnimation; */
    height: 90px;
    animation-iteration-count: 1;
    animation-direction: alternate;
    line-height: normal;
    overflow: hidden !important;
}
.ant-menu-title-content{
    padding: 0px 10px !important;
}
/* .ant-menu-light.ant-menu-horizontal >.ant-menu-submenu-open::after{
    border-bottom-color: red;
} */
/* .ant-menu-light.ant-menu-horizontal >.ant-menu-item:hover::after, 
.ant-menu-light.ant-menu-horizontal >.ant-menu-submenu:hover::after, 
.ant-menu-light.ant-menu-horizontal >.ant-menu-item-active::after, 
.ant-menu-light.ant-menu-horizontal >.ant-menu-submenu-active::after, 
.ant-menu-light.ant-menu-horizontal >.ant-menu-item-open::after,  */
.ant-menu-light.ant-menu-horizontal >.ant-menu-submenu-open::after{
    border-bottom-color: #2d5370 !important;
    width: 220px !important;
    border-width: 3px !important;
}

.menu-no-child:hover::after{
    border-bottom: none !important;
} 
.active-parent::after,
.active-submenu::after{
    border-bottom-width: 3px !important;
    border-bottom-color: #2d5370 !important;
    /* width: 80px; */
}
/* .ant-menu-light.ant-menu-horizontal >.ant-menu-submenu-active::after{
    border-bottom-color: #eb4438 !important;
    border-width: 3px !important;
} */
/* li.ant-menu-overflow-item.ant-menu-item.ant-menu-item-only-child.menu-no-child::after{
    border-bottom: none !important;
    border-bottom-color: none !important;
} */
/* li.ant-menu-overflow-item.ant-menu-item.ant-menu-item-only-child.menu-no-child:hover{
    background-color: none !important;
} */
.child-menu-item:hover{
    background-color: red;
}
.ant-menu-title-content::after{
    /* inset-inline: -50px; */
    width: 220px;
}
.ant-menu-submenu-popup{
    /* transition: 1s ease-out !important; */
    border-radius: 2px;
    top:90px !important;
    margin-top: 90;
    position: fixed;
    width: 220px !important;
    /* transition: 5s !important; */
}
.ant-menu-horizontal {
    border-bottom: none;
    height: 90px;
    line-height: 90px !important;
}
.ant-menu-horizontal .ant-menu-title-content{
    font-weight: 700;
    font-family: 'Rajdhani',sans-serif;
    font-size: 16px;
    text-transform: uppercase;
}
.ant-menu-horizontal .ant-menu-title-content:hover{
    color: #32658b !important;
}
.ant-menu-light .ant-menu-item a{
    font-family: 'Rajdhani',sans-serif;
    font-size: 17px;
    font-weight: 600;
}
/* .ant-menu-light .child-menu-item:hover{
    background-color: #487aa0 !important;
    color: #fff !important;
    border-radius: 2px !important;
} */
.active-parent{
    color: #32658b;
}
.active-submenu{
    color: #32658b;
}
.active-child{
    background-color: #2d5370;
    color: #fff;
}
/* h3{
    background-color: #5f9ea0;
    height: 200px;
    width: 200px;
    align-items: center;
    display: flex;
    text-align: center;
} */



:where(.css-dev-only-do-not-override-1s3dcof).ant-float-btn-default .ant-float-btn-body{
    background-color: rgb(207, 212, 216);
}

@keyframes testAnimation {
    from {
      /* margin-bottom: 100%; */
      height: 100vh;
      /* opacity: ; */
 
    }
  
    to {
      /* margin-bottom: 50%; */
      height: 60px;
      opacity: 0;
    }
  }
.ant-menu-submenu-popup{
    /* animation-duration: 2s; */
    /* animation-name: testAnimationTwo; */
    height: auto;
    /* opacity: 1; */
}
.ant-menu-light.ant-menu-submenu>.ant-menu{
    
}

@keyframes testAnimationTwo {
    from {
      height: 0%;
      /* opacity: 0; */
    }
  
    to {
     height: 10%;
     /* opacity: 1; */
    }
  }


/* .slick-prev:before, .slick-next:before {
    background-color: #0b0f13;
    border-radius: 50%;
    color: #fff !important;
    opacity: .7;
    width: 110px !important;
} */



.about-sticky-section-row .sticky{
    height: 7vh;
    background-color: #fff;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid rgb(173, 170, 170);
}
.about-sticky-section-row .stick{
    position: fixed;
    z-index: 5;
    top: 90px;
}
.about-sticky-section-row .about-us-haslink li{
    display: inline-block;
    padding: 5px 10px;
    text-transform: uppercase;
    font-family: 'Rajdhani',sans-serif;
    font-weight: 600;
    font-size: 15px;
}
.about-sticky-section-row  .active-hashlink{
    color: red !important;
}