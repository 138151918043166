/* .login-main-container{
    height: 100vh;
    width:100%;
    background-image:linear-gradient(45deg,rgba(53, 49, 107, 0.7),rgba(10, 76, 141, 0.7)), url('../../assets//images//bg.jpg');
    background-repeat: no-repeat;
    background-size: 100% 100%;
} */
.hero-bg-row{
    height: 55vh;
    width: 100vw;
    /* background-image: linear-gradient(45deg,rgba(20, 19, 31, 0.7),rgba(20, 19, 31, 0.7)), url('../../assets//images//contactbg.jpg'); */
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    margin-top: 60px;
}
.hero-bg-row h2{
    font-family: 'Rajdhani',sans-serif;
    font-size: 45px;
    font-weight: 600;
    color: #fff;
}