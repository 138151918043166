.our-brand-row{
    min-height: 40vh;
    overflow: hidden;
    width:100%;
    /* background-image:linear-gradient(45deg,rgba(173, 54, 24, 0.9),rgba(173, 54, 24, 0.9)), url('../../assets//images//bg.jpg'); */
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 0% 0;
    padding-bottom: 0 !important;
}
.our-brand-row .carousel-div{
    height: 30vh;
    width: 12.5vw;
    padding: 2% 4%;
    overflow: hidden;
    position:relative;
    
}
.our-brand-row .carousel-div .brand-large-img {
    position: absolute;
    transition: 1s ease-out;
    backface-visibility: hidden;
  }
  .our-brand-row .carousel-div .brand-large-img:nth-child(2) {
    transform: rotateY(180deg);
  }
  .our-brand-row .carousel-div:hover .brand-large-img:nth-child(2) {
    transform: rotateY(0deg);
  }
  .our-brand-row .carousel-div:hover .brand-large-img:nth-child(1) {
    transform: rotateY(-180deg);
  }
  
.our-brand-row .carousel-div-inner{
    overflow: hidden;
    height: 100%;
    width: 100%;
    background-color: rgb(0, 0, 0);
    position: relative;
    transform-style: preserve-3d;
    perspective: 1000px;
    /* border: 1px solid rgb(255, 255, 255); */
}
.our-brand-row .react-multi-carousel-item {
    flex-shrink: 0 !important;
    flex-grow: 0 !important;
    flex-basis: 0;
    width: 12.5vw !important;
}
.our-brand-row  li.react-multi-carousel-item.react-multi-carousel-item--active{
    flex-shrink: 0 !important;
    flex-grow: 0 !important;
    flex-basis: 0;
    width: 12.5vw !important;
}
.our-brand-row li.react-multi-carousel-item.react-multi-carousel-item--active:hover{
    /* background-color: rgb(11 34 57 / 74%) !important; */
    transition: .5s ease;
}
.our-brand-row .carousel-div .brand-large-img{
    transition: 1s ease-out;
    height: 100%;
    width: 100%;
    opacity: 1;
    display: block;
    backface-visibility: hidden;
    background-color: #000;
    /* height: 100%;
    width: 100%; */
}
.our-brand-row .overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    display: flex;
    align-items: center;
    text-align: center;
    width: 100%;
    /* margin: 0px 4%; */
    height: 20%;
    background-color: rgb(0, 0, 0);
    transition: .5s ease;
}
.our-brand-row .overlay .text{
    width: 100%;
    text-align: center;
}
.our-brand-row .carousel-div .brand-large-img:hover{
    /* transform: scale(1.2); */
    transition: 1s ease-out;
    /* background: rgba(black, 0.5) !important;
    background-color: rgba(black, 0.5) !important; */
    opacity: .5;
    cursor: pointer;
}

.our-brand-row .middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
    /* background-color: #000; */
}

  
.our-brand-row .carousel-div:hover .middle {
    opacity: 1;
}
  
.our-brand-row .text {
    /* background-color: none;
    background: none; */
    color: white;
    font-family: 'Rajdhani',sans-serif;
    font-size: 20px;
    font-weight: 600;
}
.brand-images{
    height: 18vh;
    width: 18%;
    margin: 1%;
}
.brand-images img{
    height: 100%;
    width: 100%;
}



/*** START BUTTON CSS ***/
.our-brand-row .our-brand div[class*=box] {
    height: 33.33%;
    width: 100%; 
    display: flex;
    justify-content: center;
    align-items: center;
}
    
.our-brand-row .our-brand .box-2 { background-color: #3C3C3C; }
.our-brand-row .our-brand .box-3 { background-color: #66A182; }
    
.our-brand-row .our-brand .btn {
    line-height: 32px;
    height: 32px;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
}
  
.our-brand-row .our-brand .btn-one {
    font-family: 'Rajdhani',sans-serif;
    font-weight: 600;
    color: #000;
    transition: all 0.3s;
    position: relative;
    background-color: #fff;
}
.our-brand-row .our-brand .btn-one span {
    transition: all 0.3s;
}
.our-brand-row .our-brand .btn-one::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    transition: all 0.3s;
    border-top-width: 1px;
    border-bottom-width: 1px;
    border-top-style: solid;
    border-bottom-style: solid;
    border-top-color: #fff;
    border-bottom-color: #fff;
    transform: scale(0.1, 1);
}
.our-brand-row .our-brand .btn-one:hover span {
    letter-spacing: 2px;
}
.our-brand-row .our-brand .btn-one:hover::before {
    opacity: 1; 
    transform: scale(1, 1); 
}
.our-brand-row .our-brand .btn-one::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    transition: all 0.3s;
    background-color: rgba(177, 174, 174, 0.3);
}
.our-brand-row .our-brand .btn-one::after {
    opacity: 0; 
    transform: scale(0.1, 1);
}

#expand-id{
    overflow: hidden;
    transition: height 1500ms;
}
/* #expand-id.hide {
    height: 0;
    opacity: 0;
    overflow: hidden;
  } */

  #expand-id.hide {
    /* transition: .2s ease-out;
    height: 0;
    opacity: 0; */
    height: 0;
  }
  #expand-id.show {
    /* transition: .2s ease-out; */
    height: auto;
  }

.our-brand-row .collapsed-div{
    opacity: 0;
    transition: opacity 0.8s linear; 
    animation: brandHideAnimation;
    animation-duration: .5s;
    height: 0;
}

.our-brand-row  .expand-div{
    opacity: 1;
    transition: opacity 0.5s linear;
    animation: brandShowAnimation;
    animation-duration: .5s;
    height: auto;
}


@keyframes brandShowAnimation {
    from {
      height: 0%;
    }
  
    to {
      height: auto;
    }
}
@keyframes brandHideAnimation {
    from {
      height: 100%;
    }
  
    to {
      height: 0;
    }
}


