.sustainabilty-main-row{
    font-family: 'Rajdhani',sans-serif;
    padding: 8%;
    padding-top: 2%;
    padding-bottom: 4%;
    text-align: center;
    overflow: hidden;
    background-color: #fff;
}
/* .sustainability-col-quote{
    margin-bottom: 10vw;
} */
.sustainabilty-main-row .quote-row{
    margin-bottom: 1vh;
}
.sustainabilty-main-row .quote-row h2{
    font-family: 'Rajdhani',sans-serif;
    font-weight: 600;
    /* font-size: 42px; */
    font-size: 50px;
}
.sustainabilty-main-row .gallery-row .gallery-col .ant-col-8{
    height: 25vw;
    width: 33.33%;
    overflow: hidden;
}
.sustainabilty-main-row .gallery-row .gallery-col .ant-col-8 img{
  margin: 2% 4%;
  height: 96%;
  width: 92%;
}
.explore-sustainability{
    margin-top: 6%;
}

.sustainabilty-main-row div[class*=box] {
    height: 33.33%;
    width: 100%; 
    display: flex;
    justify-content: center;
    align-items: center;
}
    
.sustainabilty-main-row  .box-2 { background-color: #3C3C3C; }
.sustainabilty-main-row  .box-3 { background-color: #66A182; }
    
.sustainabilty-main-row  .btn {
    line-height: 35px;
    height: 35px;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
}
  
.sustainabilty-main-row  .btn-one {
    font-family: 'Rajdhani',sans-serif;
    font-weight: 600;
    color: #fff;
    transition: all 0.3s;
    position: relative;
    background-color: rgb(39 66 87);
}
.sustainabilty-main-row  .btn-one span {
    transition: all 0.3s;
}
.sustainabilty-main-row  .btn-one::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    transition: all 0.3s;
    border-top-width: 1px;
    border-bottom-width: 1px;
    border-top-style: solid;
    border-bottom-style: solid;
    border-top-color: #000;
    border-bottom-color: #000;
    transform: scale(0.1, 1);
}
.sustainabilty-main-row .btn-one:hover span {
    letter-spacing: 2px;
}
.sustainabilty-main-row  .btn-one:hover::before {
    opacity: 1; 
    transform: scale(1, 1); 
}
.sustainabilty-main-row .btn-one::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    transition: all 0.3s;
    background-color: rgba(133, 124, 124, 0.3);
}
.sustainabilty-main-row  .btn-one::after {
    opacity: 0; 
    transform: scale(0.1, 1);
}
  
  
  /*** RESPONSIVE AREA ***/
  @media only screen and (min-width:480px) {
    .sustainabilty-main-row .quote-row h2{
      font-size: 19px;
    }
    .sustainabilty-main-row div[class*=box]{
      height: 15%;
    }
    .sustainabilty-main-row .btn{
      line-height: 22px;
      height: 22px;
    }
    .sustainabilty-main-row .btn-one span{
      font-size: 11px;
    }
  }
  @media only screen and (min-width:768px) {
    .sustainabilty-main-row .quote-row h2{
      font-size: 26px;
    }
    .sustainabilty-main-row .btn{
      line-height: 28px;
      height: 28px;
    }
    .sustainabilty-main-row .btn-one span{
      font-size: 12px;
    }
  }
  @media only screen and (min-width:980px) {
    .sustainabilty-main-row .quote-row  h2{
      font-size: 32px;
    }
    .sustainabilty-main-row div[class*=box]{
      height: 25%;
    }
    .sustainabilty-main-row .btn{
      line-height: 30px;
      height: 30px;
    }
    .sustainabilty-main-row .btn-one span{
      font-size: 13px;
    }
  }
  @media only screen and (min-width:1366px) {
    .sustainabilty-main-row .quote-row  h2{
      font-size: 40px;
    }
    .sustainabilty-main-row div[class*=box]{
      height: 30%;
    }
    .sustainabilty-main-row .btn{
      line-height: 32px;
      height: 32px;
    }
    .sustainabilty-main-row .btn-one span{
      font-size: 13px;
    }
  }
  @media only screen and (min-width:1920px) {
    .sustainabilty-main-row .quote-row  h2{
      font-size: 50px;
    }
    .sustainabilty-main-row div[class*=box]{
      height: 30%;
    }
    .sustainabilty-main-row .btn{
      line-height: 35px;
      height: 35px;
    }
    .sustainabilty-main-row .btn-one span{
      font-size: 14px;
    }
  }
