.overview-countup-row{
    min-height: 65vh;
    overflow: hidden;
    width:100%;
    background-image:linear-gradient(45deg,rgba(0, 0, 0, 0.6),rgba(0, 0, 0, 0.6)), url('../../assets/images/design.JPG');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    text-align: center;
}
.overview-countup-col{
    width: 25vw;
    text-align: center;
}
.overview-countup-col .countNumber{
    font-family: 'Rajdhani',sans-serif;
    color: #fff;
    font-size: 42px;
    font-weight: 600;
    margin: 0;
}
.overview-countup-col p{
    padding: 0;
    margin: 0;
}
.overview-countup-col .countTitle{
    font-family: 'Rajdhani',sans-serif;
    margin: 0;
    font-size: 22px;
    font-weight: 500;
    color: #fff;
}



  /*** RESPONSIVE AREA ***/
  @media only screen and (min-width:480px) {
    .overview-countup-row .countNumber{
      font-size: 19px;
    }
    .overview-countup-col .countTitle{
        font-size: 14px;
    }
  }
  @media only screen and (min-width:768px) {
    .overview-countup-row .countNumber{
      font-size: 28px;
    }
    .overview-countup-col .countTitle{
        font-size: 18px;
    }
    
  }
  @media only screen and (min-width:980px) {
    .overview-countup-row .countNumber{
      font-size: 32px;
    }
    .overview-countup-col .countTitle{
        font-size: 20px;
    }
  }
  @media only screen and (min-width:1366px) {
    .overview-countup-row .countNumber{
      font-size: 32px;
    }
    .overview-countup-col .countTitle{
        font-size: 20px;
    }
  }
  @media only screen and (min-width:1920px) {
    .overview-countup-row .countNumber{
      font-size: 40px;
    }
    .overview-countup-col .countTitle{
        font-size: 22px;
    }
  }