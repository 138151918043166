.get-know-about-row{
    font-family: 'Rajdhani',sans-serif;
    padding: 8%;
    padding-top: 5%;
    text-align: center;
    background-color: #000;
    color: #fff;
}
.get-know-about-row h2{
    font-family: 'Rajdhani',sans-serif;
    font-weight: 600;
    font-size: 50px;
}
.get-know-about-row button{
    font-family: 'Rajdhani',sans-serif;
    border-color: blue;
    font-weight: 600;
}

.center {
    width: 180px;
    height: 60px;
    position: absolute;
}
/* 
.btn {
    width: 180px;
    height: 60px;
    cursor: pointer;
    background: transparent;
    border: 1px solid #a19c9c !important;
    border: 1px solid #000 !important;
    color: #000;
    outline: none;
    transition: 1s ease-in-out;
  }
  
  svg {
    position: absolute;
    left: 0;
    top: 0;
    fill: none;
    stroke: #fff;
    stroke-dasharray: 150 480;
    stroke-dashoffset: 150;
    transition: 1s ease-in-out;
  }
  
  .btn:hover {
    transition: 1s ease-in-out;
    background: #4F95DA;
  }
  
  .btn:hover svg {
    stroke-dashoffset: -480;
  }
  
  .btn span {
    color: white;
    font-size: 18px;
    font-weight: 100;
  } */

.get-know-about-row div[class*=box] {
  height: 33.33%;
  width: 100%; 
  display: flex;
  justify-content: center;
  align-items: center;
}
  
/* .box-1 { background-color: #FF6766; } */
.get-know-about-row  .box-2 { background-color: #3C3C3C; }
.get-know-about-row  .box-3 { background-color: #66A182; }
  
.get-know-about-row  .btn {
  line-height: 35px;
  height: 35px;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
}

.get-know-about-row  .btn-one {
  font-family: 'Rajdhani',sans-serif;
  font-weight: 600;
  color: #fff;
  transition: all 0.3s;
  position: relative;
  background-color: #eb4438;
}
.get-know-about-row  .btn-one span {
  transition: all 0.3s;
}
.get-know-about-row  .btn-one::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  transition: all 0.3s;
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-top-style: solid;
  border-bottom-style: solid;
  border-top-color: #000;
  border-bottom-color: #000;
  transform: scale(0.1, 1);
  }
.get-know-about-row .btn-one:hover span {
  letter-spacing: 2px;
}
.get-know-about-row  .btn-one:hover::before {
  opacity: 1; 
  transform: scale(1, 1); 
}
.get-know-about-row .btn-one::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  transition: all 0.3s;
  background-color: rgba(133, 124, 124, 0.3);
}
.get-know-about-row  .btn-one::after {
    opacity: 0; 
    transform: scale(0.1, 1);
  }


/*** RESPONSIVE AREA ***/
@media only screen and (min-width:480px) {
  .get-know-about-row h2{
    font-size: 19px;
  }
  .get-know-about-row div[class*=box]{
    height: 15%;
  }
  .get-know-about-row .btn{
    line-height: 22px;
    height: 22px;
  }
  .get-know-about-row .btn-one span{
    font-size: 11px;
  }
}
@media only screen and (min-width:768px) {
  .get-know-about-row h2{
    font-size: 28px;
  }
  .get-know-about-row .btn{
    line-height: 28px;
    height: 28px;
  }
  .get-know-about-row .btn-one span{
    font-size: 12px;
  }
}
@media only screen and (min-width:980px) {
  .get-know-about-row h2{
    font-size: 32px;
  }
  .get-know-about-row div[class*=box]{
    height: 25%;
  }
  .get-know-about-row .btn{
    line-height: 30px;
    height: 30px;
  }
  .get-know-about-row .btn-one span{
    font-size: 13px;
  }
}
@media only screen and (min-width:1360px) {
  .get-know-about-row h2{
    font-size: 42px;
  }
  .get-know-about-row div[class*=box]{
    height: 30%;
  }
  .get-know-about-row .btn{
    line-height: 32px;
    height: 32px;
  }
  .get-know-about-row .btn-one span{
    font-size: 13px;
  }
}
@media only screen and (min-width:1920px) {
  .get-know-about-row h2{
    font-size: 50px;
  }
  .get-know-about-row div[class*=box]{
    height: 33.33%;
  }
  .get-know-about-row .btn{
    line-height: 35px;
    height: 35px;
  }
  .get-know-about-row .btn-one span{
    font-size: 14px;
  }
}
  
  
  