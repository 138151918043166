.the-certification-row{
    font-family: 'Rajdhani',sans-serif;
    padding: 8%;
    padding-top: 2%;
    padding-bottom: 5%;
    text-align: center;
    overflow: hidden;
    /* background-color: #fff; */
}
.the-certification-row .certification-title{
    margin-bottom: 2%;
}
.the-certification-row .certification-title h2{
    font-family: 'Rajdhani',sans-serif;
    font-weight: 600;
    font-size: 42px;
}
.the-certification-row .certification-post .ant-card{
    border-radius: 2px;
}
.the-certification-row .ant-card .ant-card-cover img{
    border-radius: 2px;
    height: 40vh;
}
.the-certification-row .post-card{
    margin: auto 2%;
    width: 29.33%;
}
.the-certification-row .post-card .card-title{
    font-family: 'Rajdhani',sans-serif;
    font-weight: 600;
    font-size: 18px;
    height: 22vh;
}
.the-certification-row .post-card .card-description{
    font-family: 'Rajdhani',sans-serif;
    font-weight: 600;
    font-size: 13px;
}


.the-certification-row  div[class*=box] {
    height: 33.33%;
    width: 100%; 
    display: flex;
    justify-content: center;
    align-items: center;
}
    
.the-certification-row   .box-2 { background-color: #3C3C3C; }
.the-certification-row   .box-3 { background-color: #66A182; }
    
.the-certification-row   .btn {
    line-height: 35px;
    height: 35px;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
}
  
.the-certification-row   .btn-one {
    font-family: 'Rajdhani',sans-serif;
    font-weight: 600;
    color: #fff;
    transition: all 0.3s;
    position: relative;
    background-color: rgb(39 66 87);
}
.the-certification-row   .btn-one span {
    transition: all 0.3s;
}
.the-certification-row   .btn-one::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    transition: all 0.3s;
    border-top-width: 1px;
    border-bottom-width: 1px;
    border-top-style: solid;
    border-bottom-style: solid;
    border-top-color: #000;
    border-bottom-color: #000;
    transform: scale(0.1, 1);
}
.the-certification-row  .btn-one:hover span {
    letter-spacing: 2px;
}
.the-certification-row   .btn-one:hover::before {
    opacity: 1; 
    transform: scale(1, 1); 
}
.the-certification-row  .btn-one::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    transition: all 0.3s;
    background-color: rgba(133, 124, 124, 0.3);
}
.the-certification-row   .btn-one::after {
    opacity: 0; 
    transform: scale(0.1, 1);
}

 
  /*** RESPONSIVE AREA ***/
  @media only screen and (min-width:480px) {
    .the-certification-row  .quote-row h2{
      font-size: 19px;
    }
    .the-certification-row  div[class*=box]{
      height: 15%;
    }
    .the-certification-row  .btn{
      line-height: 22px;
      height: 22px;
    }
    .the-certification-row  .btn-one span{
      font-size: 11px;
    }
  }
  @media only screen and (min-width:768px) {
    .the-certification-row  .certification-title h2{
      font-size: 26px;
    }
    .the-certification-row .post-card .ant-card-body{
        padding: 10px;
    }
    .ant-card .ant-card-meta-detail >div:not(:last-child){
        margin-bottom: 0;
    }
    .the-certification-row .the-certification-row .ant-card .ant-card-cover img{
        height: 30vh;
    }
    .the-certification-row .post-card .card-title{
        font-size: 15px;
    }
    .the-certification-row .post-card .card-description{
        font-size: 11px;
    }
    .the-certification-row  .btn{
      line-height: 28px;
      height: 28px;
    }
    .the-certification-row  .btn-one span{
      font-size: 12px;
    }
  }
  @media only screen and (min-width:980px) {
    .the-certification-row  .quote-row  h2{
      font-size: 32px;
    }

    .the-certification-row .post-card .card-title{
        font-size: 16px;
    }
    .the-certification-row .post-card .card-description{
        font-size: 12px;
    }
    .the-certification-row  div[class*=box]{
      height: 25%;
    }
    .the-certification-row  .btn{
      line-height: 30px;
      height: 30px;
    }
    .the-certification-row  .btn-one span{
      font-size: 13px;
    }
  }
  @media only screen and (min-width:1366px) {
    .the-certification-row  .certification-title  h2{
      font-size: 34px;
    }
    .the-certification-row .post-card .ant-card-body{
        padding: 15px;
    }
    .ant-card .ant-card-meta-detail >div:not(:last-child){
        margin-bottom: 6px;
    }
    .the-certification-row .the-certification-row .ant-card .ant-card-cover img{
        height: 40vh;
    }
    .the-certification-row .post-card .card-title{
        font-size: 18px;
    }
    .the-certification-row .post-card .card-description{
        font-size: 13px;
    }

    .the-certification-row  div[class*=box]{
      height: 30%;
    }
    .the-certification-row  .btn{
      line-height: 32px;
      height: 32px;
    }
    .the-certification-row  .btn-one span{
      font-size: 13px;
    }
  }
  @media only screen and (min-width:1920px) {
    .the-certification-row  .certification-title   h2{
      font-size: 40px;
    }

    .the-certification-row .post-card .ant-card-body{
        padding: 18px;
    }
    .ant-card .ant-card-meta-detail >div:not(:last-child){
        margin-bottom: 8px;
    }
    .the-certification-row .the-certification-row .ant-card .ant-card-cover img{
        height: 40vh;
    }
    .the-certification-row .post-card .card-title{
        font-size: 22px;
    }
    .the-certification-row .post-card .card-description{
        font-size: 16px;
    }

    .the-certification-row  div[class*=box]{
      height: 30%;
    }
    .the-certification-row  .btn{
      line-height: 35px;
      height: 35px;
    }
    .the-certification-row  .btn-one span{
      font-size: 14px;
    }
  }