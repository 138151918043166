.slideshow-filter-row{
    font-family: 'Rajdhani',sans-serif;
    padding-top: 3%;
    padding-bottom: 3%;
    text-align: center;
    background-color: #fff;
    display: flex;
    align-items: center;
    color: #000;
}
.slideshow-filter-row .button-col button{
    border-radius: 3px;
    font-family: 'Rajdhani',sans-serif;
    font-size: 17px;
    font-weight: 600;
    border-color: #274257;
    color: #000;
}
.slideshow-filter-row .button-col button:hover{
    background-color: #fff;
    color: #5d2e23;
    border-color: #8b4f42;
}
.slideshow-filter-row .button-col .active-btn{
    background-color: #274257 !important;
    transition: .5s;
    color: #fff !important;
    border-color: #274257 !important;
}
.slideshow-filter-row .button-col button{
    margin-left: .4vw;
}
.slideshow-filter-row .button-col button:first-child{
    margin-left: 0;
}
.slideshow-filter-row .slideshow-content{
    margin-top: 6vh;
}

.slideshow-filter-row .slick-prev {
    display: none !important;
}
.slideshow-filter-row  .slick-next {
  display: none !important;
}

.slideshow-filter-row .slideshow-content .our-slideshow-row .carousel-div{
    border: none !important;
}




/*** SlideShow CSS ***/

.our-slideshow-row{
    min-height: 30vh;
    overflow: hidden;
    width:100%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 0% 0;
    padding-bottom: 0 !important;
}
.ant-ribbon.ant-ribbon-placement-start{
    background-color: rgb(0, 0, 0,.5);
    font-family: 'Rajdhani',sans-serif;
    font-size: 14px;
}
.ant-ribbon .ant-ribbon-corner{
    color: rgb(0, 0, 0,.5);
}
.our-slideshow-row .carousel-main{
    /* padding: 10px !important; */
}
.our-slideshow-row .carousel-div{
    height: 45vh;
    width: 20vw !important;
    overflow: hidden;
    position: relative;
    background: #000;
    border: 1px solid rgb(245 245 245);
}

/* .our-slideshow-row li.react-multi-carousel-item.react-multi-carousel-item--active:hover{
    transition: .5s ease;
} */
.our-slideshow-row .carousel-div img{
    transition: 1.5s ease-out;
    height: 100%;
    width: 100%;
    opacity: 1;
    display: block;
    /* transition: .5s ease; */
    backface-visibility: hidden;
    background-color: #000;
}
.our-slideshow-row .carousel-div .product-desc{
    display: flex;
    align-items: center;
    /* background-color: rgb(255, 255, 255,.5); */
    background-color: #fff;
    
    text-align: center !important;
    border-left:1px solid #fff;
    border-right:1px solid #fff;
}
.our-slideshow-row .carousel-div .product-desc h3{
    font-family: 'Rajdhani',sans-serif;
    font-size: 22px;
    /* color: #fff; */
    color: #000;
    text-transform: capitalize;
}
/* .our-slideshow-row .carousel-div img:hover{
    transform: scale(1.4);
    transition: 1.5s ease-out;
    opacity: .5;
    cursor: pointer;
} */
/* .our-slideshow-row .middle:hover{
    opacity: .5;
} */

.our-slideshow-row .middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
    /* background-color: #000; */
}

  
.our-slideshow-row .carousel-div:hover .middle {
    opacity: 1;
}
  
.our-slideshow-row .text {
    /* background-color: none;
    background: none; */
    color: white;
    font-family: 'Rajdhani',sans-serif;
    font-size: 20px;
    font-weight: 600;
}
.our-slideshow-row .overly-image{
    transform: scale(1.4);
    transition: 1.5s ease-out;
    opacity: .5 !important;
    cursor: pointer;
}
.our-slideshow-row .product-btn{
    background-color: #eb4438;
    border-color: #eb4438;
    color: #fff;
    font-weight: 700;
    height: 4.5vh;
    width: 3.5vw;
}





@media only screen and (min-width:480px) {
    .our-slideshow-row .product-btn{
        font-size: 22px;
    }
    .ant-ribbon.ant-ribbon-placement-start{
        width: 20% !important;
    }
}
@media only screen and (min-width:768px) {
    .our-slideshow-row .carousel-div .product-desc h3{
        font-size: 11px;
    }
    .our-slideshow-row .product-btn{
        font-size: 10px;
        height: 5vh;
        width: 3.5vw;
    }
    /* .ant-ribbon.ant-ribbon-placement-start{
        width: 20% !important;
    } */
}
@media only screen and (min-width:980px) {
    .our-slideshow-row .carousel-div .product-desc h3{
        font-size: 12px;
    }
    .our-slideshow-row .product-btn{
        height: 5vh;
        width: 3.5vw;
        font-size: 10px;
    }
}
@media only screen and (min-width:1366px) {
    .our-slideshow-row .carousel-div .product-desc h3{
        font-size: 15px;
    }
    .our-slideshow-row .product-btn{
        font-size: 12px;
        height: 4.5vh;
        width: 3vw;
    }
    /* .ant-ribbon.ant-ribbon-placement-start{
        width: 25% !important;
    } */
}
@media only screen and (min-width:1920px) {
    .our-slideshow-row .carousel-div .product-desc h3{
        font-size: 20px;
    }
    .our-slideshow-row .product-btn{
        font-size: 22px;
    }
    .ant-ribbon.ant-ribbon-placement-start{
        width: 20% !important;
    }
}
/* .our-slideshow-row .product-btn{
    transition: 1.5s ease-out;
    animation-duration: 1.5s;
    animation-name: productbtnanimation;
    animation-iteration-count: 1;
    height: 30px;
    width: 30px;
}
@keyframes productbtnanimation {
    from {
      height: 0px;
      width: 0px;
    }
  
    to {
        height: 20px;
        width: 20px;
    }
  } */
