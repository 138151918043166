.award-certification-csr-row{
    font-family: 'Rajdhani',sans-serif;
    padding: 8%;
    padding-top: 3%;
    padding-bottom: 3%;
    height: 70vh;
    text-align: center;
    overflow: hidden;
    /* background-color: #fff; */
    background-image:linear-gradient(45deg,rgba(0, 0, 0, 0.7),rgba(0, 0, 0, 0.7)), url('../../assets/images/banner.jpg');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    text-align: center;
}
.award-certification-csr-child-col{
    padding: 10px;
    padding-right: 25px;
}
.award-certification-csr-child-col:last-child{
    padding-right: 0;
}
.award-certification-csr-child-col .ant-card{
    border-radius: 2px;
    min-height: 52vh;
    width: 100%;
}
.award-certification-csr-child-col .ant-card .ant-card-body{
    padding: 5px;
}
.award-certification-csr-child-col .ant-card img{
    height: 15vh;
    width: 100%;
}
.award-certification-csr-child-col .ant-card .ant-card-body h2{
    font-family: 'Rajdhani',sans-serif;
    font-size: 30px;
    margin-top: 1vh;
    margin-bottom: 1vh;
}






.award-certification-csr-row  div[class*=box] {
    height: 33.33%;
    width: 100%; 
    display: flex;
    justify-content: center;
    align-items: center;
}
    
.award-certification-csr-row   .box-2 { background-color: #3C3C3C; }
.award-certification-csr-row   .box-3 { background-color: #66A182; }
    
.award-certification-csr-row   .btn {
    line-height: 35px;
    height: 35px;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
}
  
.award-certification-csr-row   .btn-one {
    font-family: 'Rajdhani',sans-serif;
    font-weight: 600;
    color: #fff;
    transition: all 0.3s;
    position: relative;
    background-color: #eb4438;
}
.award-certification-csr-row   .btn-one span {
    transition: all 0.3s;
}
.award-certification-csr-row   .btn-one::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    transition: all 0.3s;
    border-top-width: 1px;
    border-bottom-width: 1px;
    border-top-style: solid;
    border-bottom-style: solid;
    border-top-color: #000;
    border-bottom-color: #000;
    transform: scale(0.1, 1);
}
.award-certification-csr-row  .btn-one:hover span {
    letter-spacing: 2px;
}
.award-certification-csr-row   .btn-one:hover::before {
    opacity: 1; 
    transform: scale(1, 1); 
}
.award-certification-csr-row  .btn-one::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    transition: all 0.3s;
    background-color: rgba(133, 124, 124, 0.3);
}
.award-certification-csr-row   .btn-one::after {
    opacity: 0; 
    transform: scale(0.1, 1);
}