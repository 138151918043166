.know-about-us-row{
    font-family: 'Rajdhani',sans-serif;
    padding: 2%;
    padding-top: 2%;
    padding-bottom: 1%;
    /* background-color: #fff; */
    text-align: center;
    overflow: hidden;
}
.know-about-us-row h2{
    font-family: 'Rajdhani',sans-serif;
    font-weight: 600;
    font-size: 50px;
}


.know-about-us-row div[class*=box] {
    height: 33.33%;
    width: 100%; 
    display: flex;
    justify-content: center;
    align-items: center;
}
    
.know-about-us-row  .box-2 { background-color: #3C3C3C; }
.know-about-us-row  .box-3 { background-color: #66A182; }
    
.know-about-us-row  .btn {
    line-height: 35px;
    height: 35px;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
}
  
.know-about-us-row  .btn-one {
    font-family: 'Rajdhani',sans-serif;
    font-weight: 600;
    color: #fff;
    transition: all 0.3s;
    position: relative;
    background-color: #eb4438;
}
.know-about-us-row  .btn-one span {
    transition: all 0.3s;
}
.know-about-us-row  .btn-one::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    transition: all 0.3s;
    border-top-width: 1px;
    border-bottom-width: 1px;
    border-top-style: solid;
    border-bottom-style: solid;
    border-top-color: #000;
    border-bottom-color: #000;
    transform: scale(0.1, 1);
}
.know-about-us-row .btn-one:hover span {
    letter-spacing: 2px;
}
.know-about-us-row  .btn-one:hover::before {
    opacity: 1; 
    transform: scale(1, 1); 
}
.know-about-us-row .btn-one::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    transition: all 0.3s;
    background-color: rgba(133, 124, 124, 0.3);
}
.know-about-us-row  .btn-one::after {
    opacity: 0; 
    transform: scale(0.1, 1);
}
  
  
  /*** RESPONSIVE AREA ***/
  @media only screen and (min-width:480px) {
    .know-about-us-row h2{
      font-size: 25px;
    }
    .know-about-us-row div[class*=box]{
      height: 15%;
    }
    .know-about-us-row .btn{
      line-height: 22px;
      height: 22px;
    }
    .know-about-us-row .btn-one span{
      font-size: 11px;
    }
  }
  @media only screen and (min-width:768px) {
    .know-about-us-row h2{
      font-size: 35px;
    }
    .know-about-us-row .btn{
      line-height: 28px;
      height: 28px;
    }
    .know-about-us-row .btn-one span{
      font-size: 12px;
    }
  }
  @media only screen and (min-width:980px) {
    .know-about-us-row h2{
      font-size: 38px;
    }
    .know-about-us-row div[class*=box]{
      height: 25%;
    }
    .know-about-us-row .btn{
      line-height: 30px;
      height: 30px;
    }
    .know-about-us-row .btn-one span{
      font-size: 13px;
    }
  }
  @media only screen and (min-width:1360px) {
    .know-about-us-row h2{
      font-size: 40px;
    }
    .know-about-us-row div[class*=box]{
      height: 33.33%;
    }
    .know-about-us-row .btn{
      line-height: 32px;
      height: 32px;
    }
    .know-about-us-row .btn-one span{
      font-size: 13px;
    }
  }
  @media only screen and (min-width:1920px) {
    .know-about-us-row h2{
      font-size: 50px;
    }
    .know-about-us-row div[class*=box]{
      height: 33.33%;
    }
    .know-about-us-row .btn{
      line-height: 35px;
      height: 35px;
    }
    .know-about-us-row .btn-one span{
      font-size: 14px;
    }
  }