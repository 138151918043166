.our-services-row{
    min-height: 40vh;
    overflow: hidden;
    width:100%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 1% 0;
    padding-bottom: 5%;
    /* background-color: #fff; */
    /* padding-bottom: 0 !important; */
}
.ant-ribbon.ant-ribbon-placement-start{
    background-color: rgb(0, 0, 0,.5);
    font-family: 'Rajdhani',sans-serif;
    font-size: 14px;
}
.ant-ribbon .ant-ribbon-corner{
    color: rgb(0, 0, 0,.5);
}
.our-services-row .carousel-main{
    /* padding: 10px !important; */
}
.our-services-row .carousel-div{
    height: 60vh;
    width: 20vw !important;
    overflow: hidden;
    position: relative;
    background: #000;
    border: 1px solid rgb(165, 167, 168);
    border-left: none;
}

/* .our-services-row li.react-multi-carousel-item.react-multi-carousel-item--active:hover{
    transition: .5s ease;
} */
.our-services-row .carousel-div img{
    transition: 1.5s ease-out;
    height: 100%;
    width: 100%;
    opacity: 1;
    display: block;
    /* transition: .5s ease; */
    backface-visibility: hidden;
    background-color: #000;
}
.our-services-row .carousel-div .product-desc{
    display: flex;
    align-items: center;
    /* background-color: rgb(255, 255, 255,.5); */
    background-color: #fff !important;
    border-top: none;
    border-bottom: none;
    
    text-align: center !important;
    border-left:1px solid #fff;
    border-right:1px solid #fff;
}
.our-services-row .carousel-div .product-desc h3{
    font-family: 'Rajdhani',sans-serif;
    font-size: 22px;
    /* color: #fff; */
    color: #000;
    text-transform: capitalize;
}
/* .our-services-row .carousel-div img:hover{
    transform: scale(1.4);
    transition: 1.5s ease-out;
    opacity: .5;
    cursor: pointer;
} */
/* .our-services-row .middle:hover{
    opacity: .5;
} */

.our-services-row .middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
    /* background-color: #000; */
}

  
.our-services-row .carousel-div:hover .middle {
    opacity: 1;
}
  
.our-services-row .text {
    /* background-color: none;
    background: none; */
    color: white;
    font-family: 'Rajdhani',sans-serif;
    font-size: 20px;
    font-weight: 600;
}
.our-services-row .overly-image{
    transform: scale(1.4);
    transition: 1.5s ease-out;
    opacity: .5 !important;
    cursor: pointer;
}
.our-services-row .product-btn{
    background-color: #eb4438;
    border-color: #eb4438;
    color: #fff;
    font-weight: 700;
    height: 4.5vh;
    width: 3.5vw;
}





@media only screen and (min-width:480px) {
    .our-services-row .product-btn{
        font-size: 22px;
    }
    .ant-ribbon.ant-ribbon-placement-start{
        width: 20% !important;
    }
}
@media only screen and (min-width:768px) {
    .our-services-row .carousel-div .product-desc h3{
        font-size: 11px;
    }
    .our-services-row .product-btn{
        font-size: 10px;
        height: 5vh;
        width: 3.5vw;
    }
    /* .ant-ribbon.ant-ribbon-placement-start{
        width: 20% !important;
    } */
}
@media only screen and (min-width:980px) {
    .our-services-row .carousel-div .product-desc h3{
        font-size: 12px;
    }
    .our-services-row .product-btn{
        height: 5vh;
        width: 3.5vw;
        font-size: 10px;
    }
}
@media only screen and (min-width:1366px) {
    .our-services-row .carousel-div .product-desc h3{
        font-size: 15px;
    }
    .our-services-row .product-btn{
        font-size: 12px;
        height: 4.5vh;
        width: 3vw;
    }
    /* .ant-ribbon.ant-ribbon-placement-start{
        width: 25% !important;
    } */
}
@media only screen and (min-width:1920px) {
    .our-services-row .carousel-div .product-desc h3{
        font-size: 20px;
    }
    .our-services-row .product-btn{
        font-size: 22px;
    }
    .ant-ribbon.ant-ribbon-placement-start{
        width: 20% !important;
    }
}
/* .our-services-row .product-btn{
    transition: 1.5s ease-out;
    animation-duration: 1.5s;
    animation-name: productbtnanimation;
    animation-iteration-count: 1;
    height: 30px;
    width: 30px;
}
@keyframes productbtnanimation {
    from {
      height: 0px;
      width: 0px;
    }
  
    to {
        height: 20px;
        width: 20px;
    }
  } */
