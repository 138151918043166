/* .about-sticky-section-row{
    
} */
.about-sticky-section-row .sticky{
    height: 7vh;
    background-color: #fff;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid rgb(173, 170, 170);
}
.about-sticky-section-row .stick{
    position: fixed;
    z-index: 5;
    top: 90px;
}
.about-sticky-section-row .about-us-haslink li{
    display: inline-block;
    padding: 5px 10px;
    text-transform: uppercase;
    font-family: 'Rajdhani',sans-serif;
    font-weight: 600;
    font-size: 15px;
}
.about-sticky-section-row  .active-hashlink{
    color: red !important;
}
.about-story-row{
    overflow: hidden;
    padding: 0% 8%;
    padding-bottom: 6%;
    background-color: #fff;
}
.about-story-row .about-content{
    padding-top:12vh;
    /* padding-bottom:4vh; */
}
.about-story-row .about-title{
    margin-top:3vh
}
.about-story-row .about-title h3{
    font-family: 'Rajdhani',sans-serif;
    font-weight: 600;
    font-size: 20px;
}
/* .about-story-row .about-description{
    margin-bottom: 15vh;
} */
.about-story-row .about-description span{
    font-family: 'Rajdhani',sans-serif;
    font-weight: 600;
    font-size: 14px;
}