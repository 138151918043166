.hero-bg-col{
    overflow: hidden;
    height:80vh;
    width: 100vw;
    margin-top: 64px;
}
.slide-image-row{
    margin-top: 64px;
}
/* .slide-bg-col .main-slide{
    height: 80vh !important;
} */
.slide-image-row .slide-image-col{
    overflow: hidden;
}
.slide-image-row .slide-image-col .slide-image{
    height: 80vh;
    width: 100vw;
}
.slide-image-row .slide-image-col .slide-image img{
    overflow: hidden;
    height: 81vh;
    width: 100%;
}

/* .ant-progress-bg{
    background-color: rgb(226 182 123) !important;
    height: 1vh !important;
    border-radius: 0 !important;
} */
.ant-progress-text{
    display: none !important;
    width: 0 !important;
}
.slider-controll-row .slider-controll-col{
  width: 100vw !important;
}

.slider-controll-row .slider-controll-col .slick-slide{
    overflow: hidden;
    position: relative;
    z-index: 5;
    height: 12.5vh;
    width: 9.09% !important;
    padding: .75vh .48vw;
    background-color: #fff;
}
.slider-controll-row .slider-controll-col .slick-slide:hover{
    cursor: pointer;
}
.slider-controll-row .slider-controll-col .slick-current{
    /* background-color: rgb(226, 182, 123) !important; */
    background-color: rgb(233, 227, 220) !important;
}
.slider-controll-row .slider-controll-col .slick-slide  img{
    /* height: 12vh; */
    height: 11vh;
    width: 8.2vw;
}
#myVideo{
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* position: fixed; */
    left: 0;
    right: 0;
    bottom: 0;
    position:absolute;
    /* transform: translate(-50%, -50%); */
    overflow:"hidden"
    /* z-index: -1; */
}

/*** RESPONSIVE AREA ***/
@media only screen and (min-width:480px) {
    .get-know-about-row h2{
      font-size: 19px;
    }
    .get-know-about-row .btn{
      line-height: 22px;
      height: 22px;
    }
    .get-know-about-row .btn-one span{
      font-size: 11px;
    }
  }
  @media only screen and (min-width:768px) {
    /* .slider-controll-row .slider-controll-col .slick-slide{
        overflow: hidden;
        height: 16vh;
        width: 12.5% !important;
        padding: 2.5vh 2.5vw;
        background-color: antiquewhite;
    } */
  }
  @media only screen and (min-width:980px) {
    /* .slider-controll-row .slider-controll-col .slick-slide{
        overflow: hidden;
        height: 16vh;
        width: 12.5vw !important;
        padding: 2.5vh 2.5vw;
        background-color: antiquewhite;
    } */
  }
  @media only screen and (min-width:1360px) {
    /* .slider-controll-row .slider-controll-col .slick-slide{
        overflow: hidden;
        height: 16vh;
        width: 12.5%!important;
        padding: 2.5vh 2.5vw;
        background-color: antiquewhite;
    } */
  }
  @media only screen and (min-width:1920px) {
    /* .slider-controll-row .slider-controll-col .slick-slide{
        overflow: hidden;
        height: 16vh;
        width: 12.5% !important;
        padding: 2.5vh 2.5vw;
        background-color: antiquewhite;
    } */
  }

  .slick-prev {
    left: 1.5% !important;
    margin-top: -6vh;
    height: 4vh;
    z-index: 1;
  }
  .slick-next {
    right: 2.5% !important;
    margin-top: -6vh;
    height: 4vh;
    z-index: 1;
  }

  .slick-prev:before, .slick-next:before{
    font-size: 35px;
  }





  .button__bar {
    overflow: hidden;
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    padding: 0;
    /* background: rgba(255, 255, 255, 0.3); */
    width: 100vw;
    height: 10.5vh;
    text-align: center;
  }
  
  .button__bar li {
    list-style: none;
    cursor: pointer;
    display: inline-block;
    width: 9.09%;
    margin: 0 0;
    padding: 0;
    /* height: 100%; */
    height: 11vh;
    background-color: #fff;
  }
  .button__bar li.slick-active{
    background-color: #e9e3dc;
  }
  .button__bar li img{
    height: 100%;
    width: 100%;
  }
.list-image img{
    height: 100%;
    width: 100%;
  }
  